import './assets/main.css'

// Import des librairies
import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import { createPinia } from 'pinia';

// Import des components
import App from './App.vue'

import Adress from './view/Adress.vue'
import Insight from './view/Insight.vue'
import Login from './view/Login.vue'
import Bracelets from './view/categories/Bracelets.vue'
import Necklaces from './view/categories/Necklaces.vue'
import Rings from './view/categories/Rings.vue'
import Cart from './view/Cart.vue'
import DetailProduct from './view/DetailProduct.vue'
import Register from './view/Register.vue'
import Home from './view/Home.vue'
import MyOrder from './view/MyOrder.vue'
import OrderDetail from './view/OrderDetail.vue'
import Profile from './view/Profile.vue'
import UpdateEmail from './view/UpdateEmail.vue'
import UpdatePassword from './view/UpdatePassword.vue'
import Wishlist from './view/Wishlist.vue'
import ForgetPassword from './view/ForgetPassword.vue'
import ResetPassword from './view/ResetPassword.vue'
import Payment from './view/Payment.vue'

const routes = [
  { path: '/accueil', component: Home, name: "home" },
  { path: '/mes-adresses', component: Adress, name: "adress" },
  { path: '/bracelets', component: Bracelets, name: "bracelets" },
  { path: '/necklaces', component: Necklaces, name: "necklaces" },
  { path: '/rings', component: Rings, name: "rings" },
  { path: '/mon-panier', component: Cart, name: "cart" },
  { path: '/produit/:id', component: DetailProduct, name: 'detailproduct' },
  { path: '/apercu', component: Insight, name: "insight" },
  { path: '/connexion', component: Login, name: "login" },
  { path: '/mes-commandes', component: MyOrder, name: "myorder" },
  { path: '/detail-commande/:id', component: OrderDetail, name: "orderdetail" },
  { path: '/mon-profil', component: Profile, name: "profile" },
  { path: '/update-email', component: UpdateEmail, name: "updateemail" },
  { path: '/update-password', component: UpdatePassword, name: "updatepassword" },
  { path: '/inscription', component: Register, name: "register" },
  { path: '/ma-wishlist', component: Wishlist, name: "wishlist" },
  { path: '/mot-de-passe-oublie', component: ForgetPassword, name: 'forgetpassword' },
  { path: '/reinitialiser-mot-de-passe', component: ResetPassword, name: 'resetpassword' },
  { path: '/paiement', component: Payment, name: 'payment' }
]

const router = createRouter({
  history: createWebHistory(),
  routes,

  scrollBehavior() {
    return { top: 0, behavior: 'smooth' };
  },
})


const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);

app.mount('#app');