<script>
import UserPanel from "../components/UserPanel.vue"
import api from '@/utils/axios';

export default {
    components: {
        UserPanel,
    },
    data() {
        return {
            userProfile: null,
            hasOrders: false,
        };
    },
    async created() {
        try {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('user_id');

            if (token && userId) {
                const response = await api.get(`/profiles/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                this.userProfile = response.data;

                const orderResponse = await api.get('/orders/user', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                this.hasOrders = orderResponse.data.orders.length > 0;
            } else {
                console.error("Token ou ID utilisateur manquant.");
            }
        } catch (error) {
            console.error("Erreur lors de la récupération du profil:", error.response.data);
        }
    },
};
</script>

<template>
    <div class="container">
        <div class="row">
            <UserPanel></UserPanel>

            <div v-if="userProfile" class="col-xl-10 offset-xl-1 col-12">
                <div class="row mt-4">
                    <div class="col-md-6 col-12 insight">
                        <h3>MON PROFIL</h3>
                        <p>{{ userProfile.civility }} {{ userProfile.name }}</p>
                        <p>{{ userProfile.email }}</p>
                        <p>
                            <router-link :to="{ name: 'profile' }">
                                Voir mon profil
                            </router-link>
                        </p>
                    </div>
                    <div class="col-md-6 col-12 insight">
                        <h3 class="mb-5">MES COMMANDES</h3>
                        <p v-if="!hasOrders">Vous n'avez pas encore commandé en tant qu'utilisateur inscrit.</p>
                        <p v-else>
                            <router-link :to="{ name: 'myorder' }">
                                Voir mes commandes
                            </router-link>
                        </p>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-6 col-12 insight">
                        <h3>MES ADRESSES</h3>
                        <p>Vous n'avez aucune adresse sauvegardée.</p>
                        <p>
                            <router-link :to="{ name: 'adress' }">
                                Voir mes adresses
                            </router-link>
                        </p>
                    </div>
                    <div class="col-md-6 col-12 insight">
                        <h3>MA WISHLIST</h3>
                        <p>Vous n'avez pas encore ajouté de produit à votre wishlist.</p>
                        <p>
                            <router-link :to="{ name: 'wishlist' }">
                                Voir ma wishlist
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.insight {
    margin: 10px;
    padding: 40px;
    border: 0.5px solid black;
    min-height: 250px;
}

@media (min-width: 768px) { /* > col-md */
  .insight {
    width: calc(50% - 20px); 
  }
}

h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}

p,
a {
    font-family: 'Arapey', serif;
    font-size: 16px;
}

a {
    color: black;
    transition: font-size 0.5s ease-in-out;
}

a:hover {
    text-decoration: underline;
    text-decoration-color: rgb(227, 167, 14);
    text-decoration-thickness: 0.1rem;
    text-underline-offset: 4px;
}

.burger-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-bottom: 10px;
}
</style>