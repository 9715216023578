<script>
import api from '@/utils/axios';
import { loadStripe } from '@stripe/stripe-js';

export default {
    data() {
        return {
            stripe: null,
            card: null,
            clientSecret: null,
            isPaymentProcessing: false,
            errorMessage: null,
        };
    },
    async created() {
        try {
            const stripeKeyResponse = await api.get('/stripe-key');
            this.stripe = await loadStripe(stripeKeyResponse.data.key);
            console.log(stripeKeyResponse.data.key);
            const elements = this.stripe.elements();
            this.card = elements.create('card');
            this.card.mount('#card-element');

            const paymentIntentResponse = await api.post('/payment-intent');
            this.clientSecret = paymentIntentResponse.data.clientSecret;
        } catch (error) {
            console.error('Erreur lors de la création du PaymentIntent:', error);
            this.errorMessage = "Une erreur est survenue lors de l'initialisation du paiement. Veuillez réessayer.";
        }
    },
    methods: {
        async initiatePayment() {
            if (!this.clientSecret) {
                console.error('Le client_secret est manquant.');
                this.errorMessage = "Un problème est survenu avec le paiement. Veuillez réessayer.";
                return;
            }

            this.isPaymentProcessing = true;
            this.errorMessage = null;

            const { error, paymentIntent } = await this.stripe.confirmCardPayment(this.clientSecret, {
                payment_method: {
                    card: this.card,
                },
                payment_method_options: {
                    card: {
                        request_three_d_secure: 'any', // Demande 3D Secure si nécessaire
                    },
                },
            });

            if (error) {
                console.error('Erreur de paiement:', error);
                this.errorMessage = error.message || "Une erreur inattendue est survenue. Veuillez réessayer.";
                this.isPaymentProcessing = false;
            } else if (paymentIntent.status === 'succeeded') {
                try {
                    await api.post('/payment-confirmation', {
                        transaction_id: paymentIntent.id,
                        amount: paymentIntent.amount / 100,
                        status: paymentIntent.status
                    });

                    await this.placeOrder();
                    alert('Paiement effectué avec succès!');
                    this.$router.push({ name: 'home' });
                } catch (error) {
                    console.error('Erreur lors de la confirmation du paiement:', error);
                    this.errorMessage = "Le paiement a été effectué, mais une erreur est survenue lors de la création de la commande.";
                } finally {
                    this.isPaymentProcessing = false;
                }
            } else {
                console.error('Le paiement n\'a pas été réussi:', paymentIntent.status);
                this.errorMessage = "Le paiement n'a pas été validé. Veuillez réessayer.";
                this.isPaymentProcessing = false;
            }
        },
        async placeOrder() {
            try {
                await api.post('/order');
                alert('Commande passée avec succès !');
            } catch (error) {
                console.error('Erreur lors de la création de la commande:', error);
                alert('Une erreur s\'est produite lors du passage de la commande.');
            }
        },
    },
};
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-10 offset-1">
                <h2>Procéder au paiement</h2>
                <form @submit.prevent="initiatePayment">
                    <div id="card-element"></div>
                    <button type="submit" :disabled="isPaymentProcessing">
                        Payer
                    </button>
                </form>
                <div v-if="errorMessage" class="alert alert-danger mt-3">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
    </div>
</template>
