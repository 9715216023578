<script>
import UserPanel from "../components/UserPanel.vue"
import api from '@/utils/axios';

export default {
    components: {
        UserPanel,
    },
    data() {
        return {
            civility: '',
            name: '',
            day: '',
            month: '',
            year: '',
            address: '',
            userProfile: null,
        };
    },
    async created() {
        try {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('user_id');

            if (token && userId) {
                const response = await api.get(`/profiles/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log(response);
                this.userProfile = response.data;

                // Initialise le formulaire
                this.civility = this.userProfile.civility;
                this.name = this.userProfile.name;
                const birthDate = new Date(this.userProfile.birthdate);
                this.day = birthDate.getDate().toString().padStart(2, '0');
                this.month = (birthDate.getMonth() + 1).toString().padStart(2, '0');
                this.year = birthDate.getFullYear().toString();
                this.address = this.userProfile.address;
            } else {
                console.error("Token ou ID utilisateur manquant.");
            }
        } catch (error) {
            console.error("Erreur lors de la récupération du profil:", error.response ? error.response.data : error);
        }
    },
    methods: {
        async updateProfile(event) {
            event.preventDefault();

            try {
                const token = localStorage.getItem('token');
                const userId = localStorage.getItem('user_id');

                if (userId && token) {
                    const birthDate = `${this.year}-${this.month}-${this.day}`;
                    const response = await api.put(`/profiles/${userId}`, {
                        civility: this.civility,
                        name: this.name,
                        birthdate: birthDate,
                        address: this.address,
                    }, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    console.log("Profil mis à jour avec succès:", response.data);
                    this.$router.push({ name: 'insight' });
                } else {
                    console.error("ID utilisateur ou token manquant.");
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.errors) {
                    console.error("Erreur lors de la mise à jour du profil:", error.response.data.errors);
                } else {
                    console.error("Erreur lors de la mise à jour du profil:", error.message);
                }
            }
        }
    },
};
</script>

<template>
    <div class="container">
        <div class="row">
            <UserPanel></UserPanel>
            <div class="col-xl-6 offset-xl-3 col-md-8 offset-md-2 col-10 offset-1">
                <h1 class="mt-5 fw-bold text-uppercase">Mon profil</h1>
                <p class="mt-4 ">Vérifiez et modifiez vos informations personnelles, votre adresse email et votre mot de
                    passe.</p>
                <div class="my-4">
                    <form @submit="updateProfile" id="" action="">
                        <div class="change_email_password row mb-4">
                            <div class="col-md-6 col-12">
                                <p>
                                    <router-link :to="{ name: 'updateemail' }">
                                        Changer d'adresse email
                                    </router-link>
                                </p>
                            </div>
                            <div class="col-md-6 col-12">
                                <p>
                                    <router-link :to="{ name: 'updatepassword' }">
                                        Changer de mot de passe
                                    </router-link>
                                </p>
                            </div>
                        </div>
                        <h2>Civilité *</h2>
                        <div class="d-flex civility">
                            <div class="radio_button">
                                <input v-model="civility" id="civility" type="radio" value="Mme">
                                <label for="civility">Mme</label>
                            </div>
                            <div class="radio_button">
                                <input v-model="civility" id="civility" type="radio" value="M">
                                <label for="civility">M.</label>
                            </div>
                        </div>
                        <div>
                            <label for="name">Nom *</label>
                            <input v-model="name" id="name" type="text" placeholder required />
                        </div>
                        <h2>Date de naissance</h2>
                        <div class="d-flex mb-5">
                            <input type="hidden" value="">
                            <div class="born_input">
                                <select v-model="day" name="day" id="day">
                                    <option value="" disabled>Jour</option>
                                    <option v-for="d in Array.from({ length: 31 }, (v, i) => i + 1)" :key="d"
                                        :value="d.toString().padStart(2, '0')">{{ d.toString().padStart(2, '0')
                                        }}
                                    </option>
                                </select>
                            </div>
                            <div class="born_input">
                                <select v-model="month" name="month" id="month">
                                    <option value="" disabled>Mois</option>
                                    <option v-for="m in Array.from({ length: 12 }, (v, i) => i + 1)" :key="m"
                                        :value="m.toString().padStart(2, '0')">{{ m.toString().padStart(2, '0')
                                        }}
                                    </option>
                                </select>
                            </div>
                            <div class="born_input">
                                <select v-model="year" name="year" id="year">
                                    <option value="" disabled>Année</option>
                                    <option
                                        v-for="y in Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i)"
                                        :key="y" :value="y">{{ y }}</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <label for="name">Adresse *</label>
                            <input v-model="address" id="address" type="text" placeholder required />
                        </div>

                        <div class="col-12 text-center">
                            <button type="submit" id="save" class="my-5 text-uppercase">sauvegarder</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-xl-6 offset-xl-2 col-md-8 offset-md-2 col-10 offset-1 user_profil_foot">
                <p class="fs-6">Pour plus de renseignements sur la façon dont nous utilisons vos données à
                    caractère personnel,
                    veuillez consulter notre <a href="">Politique de Confidentialité</a>.</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
h1,
h2,
p,
a,
input,
label {
    font-family: 'Arapey', serif;
}

p {
    font-size: 16px;
}

h2,
label {
    margin-bottom: 10px;
    margin-top: 20px;
    color: grey;
    font-size: 15px;
}

.civility label {
    margin-top: 5px;
}

a {
    color: black;
    font-size: 16px;
}

.change_email_password a {
    font-style: italic;
    transition: font-size 0.5s ease-in-out;
    font-weight: bold;
}

.change_email_password a:hover {
    text-decoration: underline;
    text-decoration-color: rgb(227, 167, 14);
}

.radio_button {
    margin-right: 60px;
}

input {
    border: 0;
    border-bottom: 1px solid #000;
    font-size: 18px;
    margin-bottom: 20px;
    width: 100%;
}

input[type="radio"] {
    width: auto;
    margin-right: 5px;
}

#profile_address {
    width: 100%;
}

.born_input {
    flex-basis: 33.33333333333%;
}

.born_input select {
    min-width: 100%;
    min-height: 30px;
    border-top: none;
}

.user_profil_foot a {
    text-decoration: underline;
}

.user_profil_foot a:hover {
    text-decoration: none;
}

button {
    min-height: 50px;
    border: 2px solid rgb(227, 167, 14);
    background-color: white;
    font-weight: bold;
    font-size: 20px;
    width: 200px;
    color: black !important;
}

@media (min-width: 768px) { /* > col-md */
    button {
        width: 300px;
    }
}

button:hover {
    background-color: rgb(227, 167, 14);
    color: white;
}
</style>
