<script>
import api from '@/utils/axios';

export default {
    data() {
        return {
            cartProducts: [],
        };
    },
    mounted() {
        this.fetchCartData();
    },
    methods: {
        async fetchCartData() {
            try {
                const response = await api.get('/cart');
                this.cartProducts = Array.isArray(response.data.cartItems) ? response.data.cartItems : [];

                for (let i = 0; i < this.cartProducts.length; i++) {
                    const productId = this.cartProducts[i].product_id;
                    const productResponse = await api.get(`/products/${productId}`);
                    const productDetails = productResponse.data;

                    this.cartProducts[i].photo_url = productDetails.photo_url;
                    this.cartProducts[i].price = productDetails.price;
                    this.cartProducts[i].totalLine = (this.cartProducts[i].price * this.cartProducts[i].quantity).toFixed(2);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération du panier:', error);
            }
        },
        async updateTotalLine(product) {
            try {
                await api.put(`/cart/${product.id}`, {
                    quantity: product.quantity,
                });
                product.totalLine = (product.price * product.quantity).toFixed(2);
            } catch (error) {
                console.error('Erreur lors de la mise à jour de la quantité:', error);
            }
        },
        async deleteProduct(product) {
            try {
                await api.delete(`/cart/${product.id}`);
                const index = this.cartProducts.indexOf(product);
                if (index > -1) {
                    this.cartProducts.splice(index, 1);
                }
            } catch (error) {
                console.error('Erreur lors de la suppression du produit:', error);
            }
        },
    },
    computed: {
        totalCart() {
            const total = this.cartProducts.reduce((totalP, product) => {
                return totalP + parseFloat(product.totalLine);
            }, 0);
            return total.toFixed(2);
        },
    },
};
</script>

<template>
    <div class="container">
        <div class="my-5">
            <div class="d-flex justify-content-center">
                <h1>PANIER</h1>
            </div>
        </div>
        <div v-if="cartProducts.length > 0">
            <div class="row">
                <div class="col-xl-9 offset-xl-2 col-md-11 offset-md-1 col-12 table-responsive">
                    <table class="table mx-auto">
                        <thead class="table-dark text-center">
                            <tr>
                                <th></th>
                                <th>Nom</th>
                                <th>Prix</th>
                                <th>Quantité</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in cartProducts" :key="product.id" class="fw-semibold">
                                <td>
                                    <img :src="product.photo_url" alt="Image du produit" class="img-fluid" />
                                </td>
                                <td class="d-sm-table-cell">{{ product.product_name }}</td>
                                <td class="price_unit">{{ product.price }} €</td>
                                <td class="quantity">
                                    <input @input="updateTotalLine(product)" v-model="product.quantity" type="number"
                                        min="1" class="border-0 text-center fw-semibold" />
                                </td>
                                <td class="fw-bold">{{ product.totalLine }} €</td>
                                <td class="mt-5 border-0">
                                    <button @click="deleteProduct(product)" class="btn"><i
                                            class="fa-regular fa-square-minus"></i></button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="fw-bold">
                            <tr class="cart_foot">
                                <td colspan="4">Total</td>
                                <td class="total_cart" colspan="1">{{ totalCart }} €</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="justify-content-center d-flex">
                    <router-link :to="{ name: 'payment' }">
                        <button type="submit" class="rounded-pill my-4 fs-5 fw-bold"
                            id="confirm-command">COMMANDER</button>
                    </router-link>
                </div>
                <div class="col-xl-10 offset-xl-2 col-10 offset-1 empty">
                    <router-link class="fs-6 fw-bold" :to="{ name: 'home' }">Continuer votre
                        shopping</router-link>
                </div>
            </div>
        </div>
        <div v-else class="row mt-5">
            <div class="col-md-10 offset-md-2 col-10 offset-1 empty">
                <p class="fs-6">Vous n'avez aucun produit dans votre panier.</p>
                <router-link class="fs-6 fw-bold" :to="{ name: 'home' }">Continuer votre shopping</router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
h1,
p,
a {
    font-family: 'Arapey', serif;
}

th {
    border: 1.5px solid black;
    min-width: 100px;
    padding: 10px;
    text-align: center;
    font-size: larger;
}

td {
    max-width: 200px;
    text-align: center;
    padding: 20px;
    vertical-align: middle;
}

.cart_foot td {
    max-width: 200px;
    border: none;
    text-align: end;
    font-size: larger;
    padding-top: 40px;
}

input {
    max-width: 38px;
}

#confirm-command {
    min-height: 50px;
    border: 2px solid rgb(227, 167, 14);
    background-color: white;
    width: 200px;
    color: black !important;
}

@media (min-width: 768px) {
    /* > col-md */
    #confirm-command {
        width: 300px;
    }
}

#confirm-command:hover {
    background-color: rgb(227, 167, 14);
    color: white;
}

a {
    color: black;
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}
</style>