<script>
import UserPanel from "../components/UserPanel.vue"

export default {
    components: {
        UserPanel,
    }
};
</script>

<template>
    <div class="container">
        <div class="row">
            <UserPanel></UserPanel>
            <div class="col-md-10 offset-md-1">
                <h3 class="fw-bold mt-5">Carnet d'adresses</h3>
                <p class="mt-4 mb-5">Sauvegardez toutes vos adresses de livraison pour effectuer plus rapidement
                    vos achats. <br> Si vous
                    avez une adresse préférée, indiquez-la comme prédéfinie : le champ sera déjà rempli dans
                    votre
                    Panier.</p>
                <div class="d-flex justify-content-between">
                    <p>Vous n'avez pas encore sauvegardé d'adresses de livraison</p>
                    <a class="d-flex" href="">
                        <i class="fa-solid fa-plus me-2"></i>
                        <h4 class="fw-bold">AJOUTER UNE NOUVELLE ADRESSE</h4>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
h3,
p {
    font-family: 'Arapey', serif;
}

p {
    color: black;
    font-size: 16px;
}

i {
    font-size: 25px;
    color: black;
}

h4 {
    font-size: 18px;
    text-decoration: underline;
    text-underline-offset: 4px;
    color: black;
}

h4:hover {
    text-decoration: none;
}
</style>